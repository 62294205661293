import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Constants } from '../../utils'
import { formatProductName, refreshFilterData } from './utils/helper'
import { displayLocation } from '../../utils/actionHelper'
import { isEmpty, map } from 'lodash'
import { Button } from '@prism/library'
import { IconSvg } from '@prism/icon'
import { searchDataUser } from '../../store/actions/searchUser'
import { CheckBoxComponent, SwitchComponent } from '../BasicComponent'
import BlockedIconCell from './BlockedIconCell'
import OktaUserForm from './OktaUserForm'
import UserManagementService from '../../services/userManagementService'
import * as ACTIONS from '../../store/actions/index'
import * as TYPE from '../../store/actions/actionTypes'

const { 
  ALL_AUCTIONS,
  COLOR,
  DEFAULT_ALL_INVENTORY,
  DEFAULT_MY_INVENTORY,
  DEFAULT_PRODUCT_VALUE,
  LABEL_TEAM_MEMBER,
  ERROR_SAVING_DATA,
  ERROR_EMAIL_ID_INVALID,
  // SMART_INSPECT_VALUE,
  LABEL_AIM_EMPLOYEE_ID,
  FIT_CONSOLE_LABEL
} = Constants

const OktaUserDetail = ({
  data,
  isEditable = false,
  onToggleActivation,
  onToggleEditMode,
  isVEManagerRole = false,
  isAdminRole = false
}) => {
  const dispatch = useDispatch()
  const { fullName, userId, emailId, applications = [], userType, inspectionCompanyName } = data
  console.info({ data });
  console.info({ inspectionCompanyName });
  const [editRowIndex, setEditRowIndex] = useState(-1)
  const [indexInvalid, setIndexInvalid] = useState(-1)
  const [isChangeDefaultValue, setIsChangeDefaultValue] = useState(false)

  useEffect(() => {
    if (!applications.length) {
      setEditRowIndex(0)
      applications.push({
        name: '',
        allInventoryAccess: false,
        authorizedToAccess: [],
        isActive: true
      })
    }
    
    else {
      applications.every((application, index) => {
        if (isEmpty(application.role)) {
          setEditRowIndex(index)
          return false
        }
        return true
      })
    }
    const isEditMode = editRowIndex > -1
    onToggleEditMode(isEditMode)
  }, [editRowIndex, applications.length])

  useEffect(() => {
    setIsChangeDefaultValue(isAdminRole && !(applications?.map(element => element.name).includes('smart_inspect')))
  }, [applications])

  const renderItemList = (arr) => {
    return map(arr, (word, index) => <div key={index}>{word}</div>)
  }

  const renderDetailAccess = (item, index, subIndex, applicationName) => {
    return (
        <>
          <td data-testid={`td-auction-${index}-${subIndex}`}>
            {displayLocation(item.locationName, item.locationCode)}
          </td>
          <td data-testid={`td-inventory-access-${index}-${subIndex}`}>
            {item.allInventoryAccess ? DEFAULT_ALL_INVENTORY : DEFAULT_MY_INVENTORY}
          </td>
          {item.groupCodes && item.groupCodes.length ?
              (<td data-testid={`td-group-code-${index}-${subIndex}`}>{renderItemList(item.groupCodes)}</td>) :
              <BlockedIconCell dataTestId={`td-group-code-${index}-${subIndex}`} />}
          {item.sellerNumbers && item.sellerNumbers.length ?
              (<td data-testid={`td-seller-number-${index}-${subIndex}`}>{renderItemList(item.sellerNumbers)}</td>) :
              <BlockedIconCell dataTestId={`td-seller-number-${index}-${subIndex}`} />}
          {applicationName === 'smart_inspect' ?
              (<td data-testid={`td-company-${index}-${subIndex}`} className={`${inspectionCompanyName === 'AiM'? 'company-aim-container' : ''}`}>
                {inspectionCompanyName}
                {inspectionCompanyName === 'AiM' ? <div className='uam-custom-input-group mt-2'>
                  <p>{LABEL_AIM_EMPLOYEE_ID}</p>
                  <input 
                    id='sbk-okta-form-aim-text'
                    type='text'
                    value={data.aimEmployeeID}
                    disabled
                  />
                </div> : <></>}
              </td>) :
              <BlockedIconCell dataTestId={`td-company-${index}-${subIndex}`} />
          }
        </>
    )
  }

  const renderActions = (isActive, index, isValidData = true) => (
    isValidData ? (
        <div>
        <Button id={`btn-edit-${index}`} data-testid={`btn-edit-${index}`}
          onClick={() => setEditRowIndex(index)}
          disabled={!isActive || (editRowIndex !== -1 && editRowIndex !== index)}>
        <IconSvg glyph="edit"
          color={!isActive || (editRowIndex !== -1 && editRowIndex !== index) ? COLOR.GRAY : COLOR.BLUE}
          height="42" />
        </Button>
        <SwitchComponent id={`cbx-activate-${index}`} dataTestId={`cbx-activate-${index}`}
          className="align-center" onChange={() => onToggleActivation(applications[index])}
          checked={isActive} value={isActive}
          disabled={(editRowIndex !== -1 && editRowIndex !== index)} />
      </div>
      ) : (
        <div className={`case-invalid-data ${indexInvalid === index ? 'show' : ''}`} 
          data-testid={`invalid-data-icon-${index}`}
          onMouseOver={() => setIndexInvalid(index)} 
          onMouseOut={() => setIndexInvalid(-1)}>
          <IconSvg glyph="error-triangle"
            height="21" />
          <p data-testid={`invalid-data-text-${index}`}>Invalid data</p>
        </div>
      )
  )


  const handleSubmitForm = async (value) => {
    const data = {...value}
    data.authorizedToAccess = data.authorizedToAccess.length ? data.authorizedToAccess.map(item => {
      return {
        ...item,
        groupCodes: item.allInventoryAccess ? undefined : item.groupCodes,
        sellerNumbers: item.allInventoryAccess ? undefined : item.sellerNumbers
      }
    }) : []
    try {
      const params = {
        ...data,
        name: fullName,
        userId: userId,
        emailId: emailId,
        userType: userType,
        product: value.name === DEFAULT_PRODUCT_VALUE ? DEFAULT_PRODUCT_VALUE : value.name
      }
      dispatch(ACTIONS.actionsPending(TYPE.ACTION_PENDING))
      const response = await UserManagementService.updateDataUser(params)
      if (response.status === 204) {
        dispatch(searchDataUser({ 
          userType: LABEL_TEAM_MEMBER, 
          userValue: encodeURIComponent(emailId)
        }))
        const activatedParams = JSON.parse(localStorage.getItem("activatedParams"))
        const deactivatedParams = JSON.parse(localStorage.getItem("deactivatedParams"))
        dispatch(refreshFilterData(activatedParams, true))
        dispatch(refreshFilterData(deactivatedParams, false)) 
        setEditRowIndex(-1)
      }
    }
    catch (error) {
      const { status } = error.response
      switch (status) {
        case 500:
        case 400:
          dispatch(ACTIONS.actionsFailure(TYPE.GET_ERR_EXTERNAL_SERVICE.FAILURE, ERROR_SAVING_DATA))
          break
        case 404:
          dispatch(ACTIONS.actionsFailure(TYPE.GET_ERR_EXTERNAL_SERVICE.FAILURE, ERROR_EMAIL_ID_INVALID))
          break
        default:
          break
      }
    }
  }

  const handleRemoveProduct = () => {
    applications.pop()
    setEditRowIndex(-1)
  }

  return (
      <>
        {map(applications, (application, index) => {
          const { authorizedToAccess } = application
          application.inspectionCompanyName = inspectionCompanyName
          if (editRowIndex !== index) {
            return (authorizedToAccess && authorizedToAccess.length) ? (
                <Fragment key={application.name}>
                  {map(authorizedToAccess, (ataItem, subIndex) => {
                    let rowSpanNum = authorizedToAccess?.length
                    if (!rowSpanNum) rowSpanNum = 1
                    return subIndex === 0 ? (
                        <tr key={subIndex} data-testid={`tr-row-${index}-${subIndex}`}>
                          <td data-testid={`td-name-${index}`} rowSpan={rowSpanNum}>
                            {fullName}
                          </td>
                          <td data-testid={`td-product-${index}`} rowSpan={rowSpanNum}>
                            {application?.name === 'fit_console' ? FIT_CONSOLE_LABEL : formatProductName(application.name)}
                          </td>
                          <td data-testid={`td-global-access-${index}`} rowSpan={rowSpanNum}>
                            <CheckBoxComponent
                                checked={application.allInventoryAccess}
                                disabled={true}
                                dataTestId={`cbx-global-access-${index}`}
                                value={application.allInventoryAccess} />
                          </td>
                          {renderDetailAccess(ataItem, index, subIndex, application.name)}
                          <td data-testid={`td-role-${index}`} rowSpan={rowSpanNum}>
                            {application?.role?.displayName}
                          </td>
                          {isEditable ? <td data-testid={`td-action-${index}`} rowSpan={rowSpanNum}>
                            {renderActions(application.isActive, index, application.isValid)}
                          </td> : <></>}
                        </tr>
                    ) : (
                        <tr key={subIndex} data-testid={`tr-row-${index}-${subIndex}`}>
                          {renderDetailAccess(ataItem, index, subIndex, application.name)}
                        </tr>
                    )
                  })}
                </Fragment>
            ) : (
                <Fragment key={application.name}>
                  <tr data-testid={`tr-row-${index}`}>
                    <td data-testid={`td-name-${index}`}>
                      {fullName}
                    </td>
                    <td data-testid={`td-product-${index}`}>
                      {application?.name === 'fit_console' ? FIT_CONSOLE_LABEL : formatProductName(application.name)}
                    </td>
                    <td data-testid={`td-global-access-${index}`}>
                      <CheckBoxComponent
                          checked={application.allInventoryAccess}
                          disabled={true}
                          dataTestId={`cbx-global-access-${index}`}
                          value={application.allInventoryAccess} />
                    </td>
                    {application.allInventoryAccess && application.name !== 'fit_console' ?
                      <td data-testid={`td-auction-${index}`}>{ALL_AUCTIONS}</td> :
                      <BlockedIconCell dataTestId={`td-auction-${index}`} />
                    }
                    <BlockedIconCell dataTestId={`td-inventory-access-${index}`} />
                    <BlockedIconCell dataTestId={`td-group-code-${index}`} />
                    <BlockedIconCell dataTestId={`td-seller-number-${index}`} />
                    {application.name === "smart_inspect" ?
                        <td data-testid={`td-company-${index}`} className={`${inspectionCompanyName === 'AiM'? 'company-aim-container' : ''}`}>
                          {inspectionCompanyName}
                          {inspectionCompanyName === 'AiM' ? <div className='uam-custom-input-group mt-2'>
                            <p>{LABEL_AIM_EMPLOYEE_ID}</p>
                            <input 
                              id='sbk-okta-form-aim-text'
                              type='text'
                              value={data.aimEmployeeID}
                              disabled
                            />
                          </div> : <></>}
                        </td> :
                        <BlockedIconCell dataTestId={`td-company-${index}`} />
                    }
                    <td data-testid={`td-role-${index}`}>
                      {application?.role?.displayName}
                    </td>
                    {isEditable ? <td data-testid={`td-action-${index}`}>
                      {renderActions(application.isActive, index, application.isValid)}
                    </td> : <></>}
                  </tr>
                </Fragment>
            )
          }
          else {
            return isEditable ? (
                <Fragment key={application.name}>
                  <OktaUserForm fullName={fullName} data={application}
                  aimEmployeeID={data.aimEmployeeID}
                  unavailableProducts={applications.map((app) => app.name)}
                  onConfirm={handleSubmitForm}
                  onCancel={() => setEditRowIndex(-1)}
                  onRemove={handleRemoveProduct}
                  isVEManagerRole={isVEManagerRole}
                  isChangeDefaultValue={isChangeDefaultValue}
                  />
                </Fragment>
            ) : (
                <tr key={index}>
                  <td data-testid={`td-name-${index}`}>
                    {fullName}
                  </td>
                  <BlockedIconCell dataTestId={`td-product-${index}`} />
                  <td data-testid={`td-global-access-${index}`}>
                    <CheckBoxComponent
                    checked={true}
                    disabled={true}
                    dataTestId={`cbx-global-access-${index}`}
                    value={true} />
                  </td>
                  {application.allInventoryAccess && application.name !== 'fit_console' ?
                      <td data-testid={`td-auction-${index}`}>{ALL_AUCTIONS}</td> :
                      <BlockedIconCell dataTestId={`td-auction-${index}`} />
                    }
                  <BlockedIconCell dataTestId={`td-inventory-access-${index}`} />
                  <BlockedIconCell dataTestId={`td-group-code-${index}`} />
                  <BlockedIconCell dataTestId={`td-seller-number-${index}`} />
                  {application.name === "smart_inspect" ?
                      <td data-testid={`td-company-${index}`}>{inspectionCompanyName}</td> :
                      <BlockedIconCell dataTestId={`td-company-${index}`} />
                  }
                  <BlockedIconCell dataTestId={`td-role-${index}`} />
                </tr>
            )
          }
        })}
      </>
  )
}

OktaUserDetail.propTypes = {
  data: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  onToggleActivation: PropTypes.func,
  onToggleEditMode: PropTypes.func,
  isVEManagerRole: PropTypes.bool,
  isAdminRole: PropTypes.bool
}

export default OktaUserDetail
