import { capitalize, map } from 'lodash'
import UserManagementService from '../../../services/userManagementService'
import * as ACTIONS from '../../../store/actions/index'
import * as TYPE from '../../../store/actions/actionTypes'
import { Constants } from '../../../utils'
import { mappingObjectFilterTable } from "../../../utils/actionHelper"


export const formatProductName = (name) => {
  if (!name) return ''

  const regex = /_/g
  const words = name.replace(regex, ' ').split(' ')
  const newWords = map(words, word => capitalize(word.toLowerCase()))
  return newWords.join(' ')
}

export const mapObjectToSelectOptions = (arr, valueStr, labelStr) => {
  if (!arr || !arr.length) return []
  return arr.map((item) => ({
    value: item[valueStr],
    label: item[labelStr]
  }))
}

export const refreshFilterData = (queryString, isActive) => async (dispatch) => {
  const defaultFilterData = { data: [], errorMessage: '', next: '', previous: '' }
  const type = isActive ? TYPE.GET_DATA_USER_ACTIVE_TAB : TYPE.GET_DATA_USER_DEACTIVE_TAB
  dispatch(ACTIONS.actionsPending(type.PENDING))
  try {
    let { data } = await UserManagementService.filterUsers(queryString)
    if (Array.isArray(data.items)) {
        const flattenItems = mappingObjectFilterTable(data.items)
        const resultObj = { ...defaultFilterData,
          data: flattenItems,
          next: data?.next,
          previous: data?.previous,
          isAscending: true,
          totalCount: data.totalCount || 0
        }
        dispatch(ACTIONS.actionsSuccess(type.SUCCESS, resultObj))
    }
  } catch (error) {
    const { status } = error.response || {}
    if (status === 500) {
      dispatch(ACTIONS.actionsFailure(type.FAILURE,  Constants.SERVICE_UNAVAILABLE))
    }
  }
}
