import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { cloneDeep, isEmpty, map } from 'lodash'
import { getRoles } from '../../store/actions/roleAction'
import { Constants } from '../../utils'
import { displayLocation, hasSpecialCharacters, removeSpace } from '../../utils/actionHelper'
import { formatProductName, mapObjectToSelectOptions } from './utils/helper'
import { Button, Checkbox } from '@prism/library'
import { IconSvg } from '@prism/icon'
import Select from 'react-select'
import { TagsInput } from '../BasicComponent'
import BlockedIconCell from './BlockedIconCell'
import {COMPANY_LIST} from "../../utils/constants";

const { 
  ALL_AUCTIONS,
  PRODUCT_OPTIONS,
  INVENTORY_OPTIONS,
  INVALID_SELLER_NUMBER,
  DEFAULT_ICON_SIZE,
  DEFAULT_ALL_INVENTORY,
  DEFAULT_MY_INVENTORY,
  DEFAULT_TYPE_OKTA,
  COLOR,
  ERROR_FETCHING_DATA,
  REQUIRED,
  LABEL_AIM_EMPLOYEE_ID
} = Constants

const OktaUserForm = ({
  fullName,
  data,
  aimEmployeeID = '',
  unavailableProducts,
  onConfirm,
  onCancel,
  onRemove,
  isVEManagerRole = false,
  isChangeDefaultValue = false
}) => {
  console.log(PRODUCT_OPTIONS[1], PRODUCT_OPTIONS)
  const dispatch = useDispatch()
  const { name: productName, authorizedToAccess = [], role } = data
  
  const productOptions = PRODUCT_OPTIONS.filter((i) => {
    return isVEManagerRole ? i.value === 'smart_inspect' : unavailableProducts.indexOf(i.value) < 0
  })
  
  const defaultValue ={ ...data, name: productName || productOptions[0]?.value, authorizedToAccess, role }

  const allAuctionOptions = useSelector(state => state.auctions.auctions)
  const locationCodes = authorizedToAccess.map((v) => v.locationCode)
  const filteredAuctionOptions = allAuctionOptions.filter((i) => locationCodes.indexOf(i.value) < 0)
  const inventoryOptions = INVENTORY_OPTIONS.slice()
  const roleOptions = useSelector(state => 
    mapObjectToSelectOptions(state.roles.rolesByUserType, 'name', 'displayName'))
  const hasLoadedRoleOptionsFailed = useSelector(state => state.roles.isError)

  const [productData, setProductData] = useState(cloneDeep(defaultValue))
  const [auctionOptions, setAuctionOptions] = useState(filteredAuctionOptions)
  const [selectedAuctionOptions, setSelectedAuctionOptions] = useState([])
  const [groupCodeValue, setGroupCodeValue] = useState([])
  const [sellerNumberValue, setSellerNumberValue] = useState([])
  const [sellerNumberErrorIndex, setSellerNumberErrorIndex] = useState(-1)
  const [aimID, setAimID] = useState(aimEmployeeID)
  const [aimIDRequiedError, setAimIDRequiedError] = useState(false)

  useEffect(() => {
    const appName = productData?.name?.toUpperCase() || productOptions[0]?.value?.toUpperCase()
    getRoles(dispatch, DEFAULT_TYPE_OKTA, appName)
  }, [productData.name])

  useEffect(() => {
    if (data.allInventoryAccess) {
      setProductData((prev) => ({ ...prev, authorizedToAccess: [] }))
    }
  }, [data])

  useEffect(() => {
    setAuctionOptions(filteredAuctionOptions)
  }, [filteredAuctionOptions.length])

  useEffect(() => {
    if (JSON.stringify(productData?.role) === "{}" || JSON.stringify(productData?.role) === undefined) {
      if (isChangeDefaultValue && productData?.name?.toUpperCase() === 'SMART_INSPECT') {
        setProductData({ ...productData, allInventoryAccess: true, authorizedToAccess: []})
      } else {
        setProductData({ ...productData, allInventoryAccess: false})
      }
    } 
  }, [productData?.name])

  const isFormInvalid = (value) => {
    if (productData.name === "smart_inspect" && !value.inspectionCompanyName) {
      return true
    }

    if (value.allInventoryAccess) {
      return false
    } else if (!value.authorizedToAccess.length) {
      return true
    } else {
      const isNotDisabled = validateInventoryAccess(value.authorizedToAccess)
      return !isNotDisabled
    }
  }

  const resetAuctionOptions = (currentAuctionList) => {
    const currentLocationCodes = currentAuctionList.map((v) => v.locationCode)
    const remainingAuctionOptions = allAuctionOptions.filter((i) => currentLocationCodes.indexOf(i.value) < 0)
    setAuctionOptions([ ...remainingAuctionOptions ])
  }

  const validateSellerNumber = (value) => {
    return !(value?.charAt(0) === '0' || value?.length < 7 || isNaN(Number(value)))
  }

  const validateInventoryAccess = (values) => {
    return values.every((item) => {
      if (!item.allInventoryAccess && 
        !item.groupCodes?.length && !item.sellerNumbers?.length
      ) {
        return false
      }
      return true
    })
  }

  const renderProductInput = () => {
    const selectedProduct = {
      value: productData.name || productOptions[0]?.value,
      label: formatProductName(productData.name) || formatProductName(productOptions[0]?.value)
    }
    return !isEmpty(role) ? formatProductName(productName) : 
    (<Select
      id="sbx-okta-form-product"
      styles={{
        option: (provided) => ({
          ...provided,
          textAlign: "left",
          cursor: "pointer"
        })
      }} 
      options={productOptions} 
      value={selectedProduct}
      onChange={handleChangeProduct} />)
  }

  const renderGlobalAccessInput = (productName = '') => {
    return (<Checkbox.Controlled
      id="cbx-okta-form-global-access" 
      checked={productData.allInventoryAccess} 
      label="" 
      disabled={productName === 'fit_console'}
      onChange={(e) => handleChangeAllInventoryAccess(e)} />
    )
  }
    

  const renderCompanyInput = () => {
    console.log({"renderCompanyInput productData": productData})
    const selectedCompany = {
      value: productData.inspectionCompanyName,
      label: productData.inspectionCompanyName
    }
    console.log({"selectedCompany": selectedCompany})
    return (<Select
        id="sbx-okta-form-company"
        styles={{
          option: (provided) => ({
            ...provided,
            textAlign: "left",
            cursor: "pointer"
          })
        }}
        options={COMPANY_LIST}
        value={selectedCompany.value ? selectedCompany : ''}
        onChange={handleChangeUserInspectionCompanyName}
        placeholder={'Select Company'}
    />)
  }

  const handleAIMIDChange = (event) => { 
    const numbericalOnlyReg = /^[0-9]*$/
    const aimIDInputValue = (event.target.value || '').substring(0, 6)
    if (numbericalOnlyReg.test(aimIDInputValue)) {
      setAimID(aimIDInputValue)
    }
  }

  const renderAIMEmployeeIDInput = () => {
    return (
      <div className='uam-custom-input-group mt-2'>
        <p>{LABEL_AIM_EMPLOYEE_ID}</p>
        <input 
          id='sbk-okta-form-aim-id'
          className={`${aimIDRequiedError ? 'error' : ''}`}
          type='text'
          onChange={handleAIMIDChange}
          value={aimID}
        />
        { aimIDRequiedError ? <label><i className="icon prism-icon-error-triangle"></i>{REQUIRED}</label> : <></>}
      </div>
    )
  }

  const renderRoleInput = () => {
    const isApplyDefaultAdminRole = isChangeDefaultValue && productData?.name?.toUpperCase() === 'SMART_INSPECT'
    const defaultRole = isApplyDefaultAdminRole ? roleOptions?.find(item => item.value === 'INSPECTION') : roleOptions[0]
    const selectedRole = {
      value: productData?.role?.name || defaultRole?.value,
      label: productData?.role?.displayName || defaultRole?.label
    }
    console.log({"selectedRole": selectedRole})
    return (
      <>
        <div className="text-danger mb-1">
          {!roleOptions.length && hasLoadedRoleOptionsFailed ? ERROR_FETCHING_DATA : ''}
        </div>
        <Select 
          id="sbx-okta-form-role"
          styles={{
            option: (provided) => ({
              ...provided,
              textAlign: "left",
              cursor: "pointer"
            })
          }}
          options={isApplyDefaultAdminRole ? roleOptions.reverse() : roleOptions} value={selectedRole}
          onChange={handleChangeRole} />
      </>
    )
  }

  const renderActionControls = () => {
    const isConfirmDisabled = isFormInvalid(productData) || isEmpty(roleOptions)
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Button 
          className="p-0 border-0 btn-icon pr-3"
          id="btn-okta-form-confirm"
          disabled={isConfirmDisabled}
          onClick={() => handleSubmitForm(productData)}>
          <IconSvg
            className="button-icon"
            glyph="checkmark-circle"
            height={DEFAULT_ICON_SIZE.ACTION}
            color={isConfirmDisabled ? COLOR.GRAY : 'secondary'} />
        </Button>
        <Button 
          className="p-0 border-0 btn-icon pr-3"
          id="btn-okta-form-cancel"
          onClick={handleCancelForm}>
          <IconSvg
            className="button-icon"
            glyph="cancel-circle-o"
            height={DEFAULT_ICON_SIZE.ACTION}
            color='secondary' />
        </Button>
      </div>
    )
  }

  const handleChangeProduct = (event) => {
    if (productData?.name?.toUpperCase() !== event.value.toUpperCase()) {
      if (productData?.name?.toUpperCase() === 'SMART_INSPECT') {
        setProductData({ ...productData, name: event.value, role: undefined, allInventoryAccess: true, authorizedToAccess: [] })
      } else {
        setProductData({ ...productData, name: event.value, role: undefined})
      } 
    }
  }

  const handleChangeAllInventoryAccess = () => {
    setProductData((prev) => ({ 
      ...prev,
      allInventoryAccess: !prev.allInventoryAccess,
      authorizedToAccess: prev.allInventoryAccess ? [ ...authorizedToAccess ] : []
    }))
  }

  const handleSelectAuctions = (values) => {
    setSelectedAuctionOptions(values)
  }

  const handleAddNewLocations = () => {
    const auctionList = [ ...productData.authorizedToAccess ]
    selectedAuctionOptions.forEach((item) => {
      auctionList.push({
        locationCode: item.value,
        locationName: item.label,
        allInventoryAccess: true
      })
    })
    setProductData({ ...productData, authorizedToAccess: [ ...auctionList ] })
    setSelectedAuctionOptions([])
    resetAuctionOptions(auctionList)
  }

  const handleRemoveAuction = (index) => {
    const auctionList = [ ...productData.authorizedToAccess ]
    auctionList.splice(index, 1)
    setProductData((prev) => ({ ...prev, authorizedToAccess: [ ...auctionList ] }))
    resetAuctionOptions(auctionList)
  }

  const handleToggleAllSubInventory = (value, index) => {
    const auctionList = [ ...productData.authorizedToAccess ]
    auctionList[index].allInventoryAccess = !!value.value
    setProductData((prev) => ({ ...prev, authorizedToAccess: [ ...auctionList ] }))
    setSellerNumberErrorIndex(-1)
  }

  const handleChangeGroupCodeInput = (e, index) => {
    const groupCodeValue = e.target.value
    if (!hasSpecialCharacters(groupCodeValue.toUpperCase()) &&
    groupCodeValue?.length <= 4) {
      setGroupCodeValue({
        [`${index}`]: removeSpace(groupCodeValue.toUpperCase())
      })
    }
  }

  const handleSelectGroupCodeTags = (values, index) => {
    setGroupCodeValue({})
    const ataItem = cloneDeep(productData.authorizedToAccess)
    ataItem[index].groupCodes = [ ...values ]
    setProductData({ ...productData, authorizedToAccess: ataItem })
  }

  const handleChangeSellerNumberInput = (e, index) => {
    const sellerNumberValue = e.target.value
    if (!hasSpecialCharacters(sellerNumberValue) && 
      !isNaN(Number(sellerNumberValue)) &&
      sellerNumberValue.length <= 7) {
      setSellerNumberValue({
        [`${index}`]: sellerNumberValue.toString().trim()
      })
    }
  }

  const handleSelectSellerNumberTags = (values, index) => {
    if (sellerNumberValue[index] && 
    !validateSellerNumber(sellerNumberValue[index])) {
      setSellerNumberErrorIndex(index)
    } else {
      setSellerNumberErrorIndex(-1)
    }
    setSellerNumberValue({})
    const validatedValues = values.filter((i) => i.toString().length === 7)
    const ataItem = cloneDeep(productData.authorizedToAccess)
    ataItem[index].sellerNumbers = [ ...validatedValues ]
    setProductData({ ...productData, authorizedToAccess: ataItem })
  }

  const handleChangeRole = (value) => {
    setProductData((prev) => ({ ...prev, role: { name: value.value, displayName: value.label } }))
  }

  const handleSubmitForm = (value) => {
    if (isEmpty(value?.role)) {
      value.role = {
        name: roleOptions[0]?.value,
        displayName: roleOptions[0]?.label
      }
    }
    if (isEmpty(value?.name)) {
      value.name = productOptions[0]?.value
    }
    if (productData.inspectionCompanyName === 'AiM' && !aimID) {
      setAimIDRequiedError(true)
      return
    }
    value['aimEmployeeID'] = aimID
    setAimIDRequiedError(false)
    onConfirm(value)
  }

  const handleCancelForm = () => {
    if (!isEmpty(role)) {
      onCancel()
    }
    else {
      if (unavailableProducts.length && unavailableProducts[0] !== '') {
        onRemove()
      }
      else {
        setProductData((prev) => ({ 
          ...prev, 
          allInventoryAccess: false,
          authorizedToAccess: [],
          name: productOptions[0]?.value,
          role: {}
        }))
      }
    }
  }

  const handleChangeUserInspectionCompanyName = (value) => {
    console.log({"handleChangeUserInspectionCompanyName value": value})
    setAimIDRequiedError(false)
    setAimID(aimEmployeeID)
    setProductData((prev) => ({ ...prev, inspectionCompanyName: value.value }))
  }

  return (
    <>
      {map(productData.authorizedToAccess, (ataItem, index) => {
        const rowSpanNum = productData.authorizedToAccess.length + 1
        return (
          <tr key={ataItem.locationCode || ''} data-testid={`tr-okta-form-${index}`}>
            {index === 0 && <>
            <td data-testid="td-okta-form-name" rowSpan={rowSpanNum}>{fullName}</td>
            <td className={isEmpty(role) ? 'text-left' : ''} data-testid="td-okta-form-product" rowSpan={rowSpanNum}>
              {renderProductInput()}
            </td>
            <td data-testid="td-okta-form-global-access" rowSpan={rowSpanNum}>
              {renderGlobalAccessInput(productData?.name)}
            </td>
            </>}
            <td data-testid={`td-okta-form-auction-${index}`} className="text-left">
              <div className="badge bg-light w-100 d-flex align-items-center justify-content-between">
                <span className='bg-light-content'>{displayLocation(ataItem?.locationName, ataItem?.locationCode)}</span>
                <Button id={`btn-okta-form-remove-auction-${index}`} onClick={() => handleRemoveAuction(index)}>
                  <i className="icon prism-icon-cross-small"></i>
                </Button>
              </div>
            </td>
            <td data-testid={`td-okta-form-inventory-access-${index}`} className="text-left">
              <Select 
                id={`sbx-okta-form-inventory-access-${index}`}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    textAlign: "left",
                    cursor: "pointer"
                  })
                }}
                options={inventoryOptions} 
                value={{ 
                  value: ataItem?.allInventoryAccess,
                  label: ataItem?.allInventoryAccess ? DEFAULT_ALL_INVENTORY : DEFAULT_MY_INVENTORY
                }}
                onChange={(e) => handleToggleAllSubInventory(e, index)} />
            </td>
            {!ataItem?.allInventoryAccess ? 
            <td data-testid={`td-okta-form-group-code-${index}`}>
              <TagsInput dataTestIdTagsInput={`tag-okta-form-group-code-${index}`}
                dataTestIdInput={`ibx-okta-form-group-code-${index}`}
                tagsValue={ataItem?.groupCodes}
                selectedTags={(e) => handleSelectGroupCodeTags(e, index)}
                inputValue={groupCodeValue[index]}
                handleChangeInput={(e) => handleChangeGroupCodeInput(e, index)}
                onBlur={() => {
                  setGroupCodeValue({})
                }} />
            </td> : <BlockedIconCell dataTestId={`td-okta-form-group-code-${index}`} />}
            {!ataItem?.allInventoryAccess ? 
            <td data-testid={`td-okta-form-seller-number-${index}`}>
              {sellerNumberErrorIndex === index ?
                <div className="text-danger mb-1 text-left">{INVALID_SELLER_NUMBER}</div> : <></>}
              <TagsInput dataTestIdTagsInput={`tag-okta-form-seller-number-${index}`}
                dataTestIdInput={`ibx-okta-form-seller-number-${index}`}
                tagsValue={ataItem?.sellerNumbers}
                selectedTags={(e) => handleSelectSellerNumberTags(e, index)}
                inputValue={sellerNumberValue[index]}
                checkAddTags={validateSellerNumber(sellerNumberValue[index])}
                handleChangeInput={(e) => handleChangeSellerNumberInput(e, index)}
                onBlur={() => {
                  setSellerNumberValue({})
                  setSellerNumberErrorIndex(-1)
                }}
                type="number" />
            </td> : <BlockedIconCell dataTestId={`td-okta-form-seller-number-${index}`} />}
            {index === 0 && <>
              { productData.name === "smart_inspect" ?
                  (<td data-testid="td-okta-form-company" rowSpan={rowSpanNum}
                    className={`${productData.inspectionCompanyName === 'AiM'? 'company-aim-container' : ''} text-left`}>
                    {renderCompanyInput()}
                    {productData.inspectionCompanyName === 'AiM' && renderAIMEmployeeIDInput()}
                  </td>) :
                  <BlockedIconCell testDataId="td-okta-form-company" />
              }
            <td data-testid="td-okta-form-role" rowSpan={rowSpanNum} className="text-left">
              {renderRoleInput()}
            </td>
            <td data-testid="td-okta-form-action" rowSpan={rowSpanNum}>
              {renderActionControls()}
            </td>
            </>}
          </tr>
        )
      })}
      <tr>
        {!productData?.authorizedToAccess?.length && <>
          <td data-testid="td-okta-form-name">{fullName}</td>
          <td data-testid="td-okta-form-product" className={isEmpty(role) ? 'text-left' : ''}>
            {renderProductInput()}
          </td>
          <td data-testid="td-okta-form-global-access">
            {renderGlobalAccessInput(productData?.name)}
          </td>
        </>}
        {!productData.allInventoryAccess ? <>
          <td data-testid="td-okta-form-auction" className="text-left">
            <div className="text-danger mb-1">
              {auctionOptions.length ? '' : ERROR_FETCHING_DATA}
            </div>
            <Select
              id="sbx-okta-form-auction"
              classNamePrefix={`${auctionOptions.length > 5 ? 'my-select-longer' :'my-select-shorter'}`}
              styles={{
                option: (provided) => ({
                  ...provided,
                  textAlign: "left",
                  cursor: "pointer"
                })
              }} 
              options={auctionOptions} isMulti value={selectedAuctionOptions}
              onChange={handleSelectAuctions} placeholder={'Select Auction'} />
          </td>
          <td colSpan={3} data-testid="td-okta-form-add-new-location" className="text-left">
            <Button id="btn-okta-form-add-new-location"
              className="btn-main" onClick={handleAddNewLocations}
              disabled={!selectedAuctionOptions.length}>
                Add new location
            </Button>
          </td>
        </> : <>
          {productData?.name === 'fit_console' ? <BlockedIconCell dataTestId="td-okta-form-auction" /> : <td data-testid="td-okta-form-auction">{ALL_AUCTIONS}</td>}
          <BlockedIconCell dataTestId="td-okta-form-inventory-access" />
          <BlockedIconCell dataTestId="td-okta-form-group-code" />
          <BlockedIconCell dataTestId="td-okta-form-seller-number" />
        </>}
        {!productData?.authorizedToAccess?.length && <>
          { productData.name === "smart_inspect" ?
              (<td data-testid="td-okta-form-company"
                className={`${productData.inspectionCompanyName === 'AiM'? 'company-aim-container' : ''} text-left`}>
                  {renderCompanyInput()}
                  {productData.inspectionCompanyName === 'AiM' && renderAIMEmployeeIDInput()}
              </td>) :
              <BlockedIconCell dataTestId="td-okta-form-company" />
          }
          <td data-testid="td-okta-form-role" className="text-left">
            {renderRoleInput()}
          </td>
          <td data-testid="td-okta-form-action">
            {renderActionControls()}
          </td>
        </>}
      </tr>
    </>
  )
}

OktaUserForm.propTypes = {
  fullName: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  aimEmployeeID: PropTypes.string,
  unavailableProducts: PropTypes.array,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onRemove: PropTypes.func,
  isVEManagerRole: PropTypes.bool,
  isChangeDefaultValue: PropTypes.bool
}

export default OktaUserForm
